import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setToogleMood, setPrimaryColor } from "../../Actions/ColorActions";
import { ColorPicker } from "../../Components/ColorPicker";
import { Footer } from "../../Components/Footer";
import TaskComponent from "../../Components/TaskComponent/TaskComponent";
import fileStructureImg from "../../Assets/Devteam/fileStructure.png";

const AmericanCournerTraning = ({
  primary,
  dark,
  light,
  setToogleMood,
  setPrimaryColor,
}) => {
  useEffect(() => {
    let flag = localStorage.getItem("light_mood");
    let colorFlag = localStorage.getItem("custom_color");
    if (flag === "true") {
      setToogleMood();
    }
    if (colorFlag) {
      setPrimaryColor({ hex: colorFlag });
    }
    console.log(colorFlag);
  }, [setToogleMood, setPrimaryColor]);
  return (
    <div
      style={{
        "--color_orange": primary,
        "--color_dark": dark,
        "--color_light": light,
        background: dark,
        width: "100%",
        height: "100%",
      }}
    >
      <ColorPicker />
      <div className="min_height pt-5">
        <TaskComponent
          title="Resources for Web Development"
          description={"All the resources links are given below"}
        >
          <div className="py-2 text-white d-flex justify-content-center align-items-center flex-column">
            <h4>Course Outline</h4>
            <a href="https://docs.google.com/document/d/1A9s5pey1AD8ve36qxN9LshBiPc78lHjqPvFWiQq-B0k/edit?usp=sharing">
              Link
            </a>
          </div>
          <div className="py-2 text-white d-flex justify-content-center align-items-center flex-column">
            <h4>
              Visual Studio Code <small>(Text Editor)</small>
            </h4>
            <a href="https://code.visualstudio.com/">
              https://code.visualstudio.com
            </a>
          </div>
          <div className="text-light d-flex justify-content-center align-items-center py-4">
            <ol>
              <li>
                HTML tag list{" "}
                <a href="https://www.w3schools.com/tags/default.asp">
                  W3Schools
                </a>
              </li>
              <li>
                CSS documentation{" "}
                <a href="https://www.w3schools.com/cssref/index.php">
                  W3Schools
                </a>
              </li>
              <li>
                Bootstrap documentation{" "}
                <a href="https://getbootstrap.com/docs/5.3/getting-started/introduction/">
                  Bootstrap
                </a>
              </li>
            </ol>
          </div>
        </TaskComponent>
      </div>
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => ({
  primary: state.color.primary,
  dark: state.color.dark,
  light: state.color.light,
});

export default connect(mapStateToProps, { setToogleMood, setPrimaryColor })(
  AmericanCournerTraning
);
