import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setToogleMood, setPrimaryColor } from "../../../Actions/ColorActions";
import { ColorPicker } from "../../../Components/ColorPicker";
import { Footer } from "../../../Components/Footer";
import TaskComponent from "../../../Components/TaskComponent/TaskComponent";
import fileStructureImg from "../../../Assets/Devteam/fileStructure.png";

const Task1 = ({ primary, dark, light, setToogleMood, setPrimaryColor }) => {
  useEffect(() => {
    let flag = localStorage.getItem("light_mood");
    let colorFlag = localStorage.getItem("custom_color");
    if (flag === "true") {
      setToogleMood();
    }
    if (colorFlag) {
      setPrimaryColor({ hex: colorFlag });
    }
    console.log(colorFlag);
  }, [setToogleMood, setPrimaryColor]);
  return (
    <div
      style={{
        "--color_orange": primary,
        "--color_dark": dark,
        "--color_light": light,
        background: dark,
        width: "100%",
        height: "100%",
      }}
    >
      <ColorPicker />
      <div className="min_height pt-5">
        <TaskComponent
          title="Design simple hero section using only HTML & CSS"
          description={
            "In this task you have to follow the Figma file and design the hero section. Try to make a pixel perfect design. First, Second and Third will be announced for this task. So give your best."
          }
        >
          <div className="pb-5 text-center  text_light w-75 mx-auto">
            <span className="d-block text_light text-center fs-4">
              Mark distribution
            </span>
            <span className="d-block">
              1. Title Hero Section - <b>8</b>{" "}
            </span>
            <span className="d-block">
              2. Subtitle Hero Section - <b>5</b>{" "}
            </span>
            <span className="d-block">
              3. Background with images and gredient - <b>10</b>{" "}
            </span>
            <span className="d-block">
              4. Navbar - <b>10</b>{" "}
            </span>
            <span className="d-block">
              5. Responsiveness - <b>7</b>{" "}
            </span>
            <span className="d-block">
              6. Folder Structure - <b>3</b>{" "}
            </span>
            <span className="d-block">
              7. Commenting - <b>3</b>{" "}
            </span>
            <span className="d-block">
              8. HTML validation using
              (https://validator.w3.org/#validate_by_input) - <b>2</b>{" "}
            </span>
            <span className="d-block">
              9. CSS validation using
              (https://jigsaw.w3.org/css-validator/#validate_by_input)- <b>2</b>{" "}
            </span>
            <span className="d-block pt-3">
              <b>Note: </b>To get full mark in background section try to make
              the gradident of the background using CSS. If you find making the
              background from scratch hard then export the background group of
              the figma as png and use it as background. Follow the folder
              structure given below. Add comments for each section in your code.
              Validate your HTML and CSS code using the validation tools given
              above. Take the screenshot of validation result and submit with
              the code when asked.
            </span>
          </div>

          <div className="text-center">
            <img src={fileStructureImg} alt="" className="w-25 mx-auto" />
          </div>
          <div className="text-center pb-5">
            <a
              className="link_text fs-4"
              href="https://www.figma.com/file/wq53fdjIEH57PkHqNrynZ2/Landing-page-(Community)?node-id=0%3A1&t=EwnxQ8jJzz7hl2Ay-1"
            >
              Link to Figma File
            </a>
          </div>
          <iframe
            width="100%"
            height="600"
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fwq53fdjIEH57PkHqNrynZ2%2FLanding-page-(Community)%3Fnode-id%3D0%253A1%26t%3DEwnxQ8jJzz7hl2Ay-1"
          ></iframe>
        </TaskComponent>
      </div>
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => ({
  primary: state.color.primary,
  dark: state.color.dark,
  light: state.color.light,
});

export default connect(mapStateToProps, { setToogleMood, setPrimaryColor })(
  Task1
);
