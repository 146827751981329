const data = [
  {
    id: 100,
    company: "Inovetix",
    time: "2023 - Present",
    description:
      "Inovetix has taught me a lot. It is one of the best places to show creativity and learn new skills. It has been a great learning opportunity for me at Inovetix. Also it gave me chance to learn DevOps hands on.",
    link: "https://inovetix.com/",
  },
  {
    id: 1,
    company: "Qubitech",
    time: "2021 - 2023",
    description:
      "Qubitech Solutions is an IT solutions venture helping the new age entrepreneurs, startups and business with branding, designing and developing complete products. I am learning a lot from Qubitech.",
    link: "/",
  },
  {
    id: 2,
    company: "Pioneer Aplha",
    time: "2020 - 2021",
    description:
      "I was one of the lead web developers at Pioneer Alpha. I have developed my communication skill and leadership skill while working here. I got the chance to work with AWS and know more about serverless approaches. This made me a more versatile developer.",
    link: "https://pioneeralpha.com/",
  },

  {
    id: 4,
    company: "RCF devTeam",
    time: "2019 - Present",
    description:
      "RCF (RUET Career Forum) devTeam is a web development team that was selected based on a web development competition in RUET. I achieved first place and got the chance to work as devTeam Core member. We developed the official website of RUET Career Forum. The site is live at: https://ruetcareerforum.org",
    linkInside: "https://ruetcareerforum.org/",
  },
];

export default data;
