import React from "react";
import { BorderedSection } from "../Shared/BorderedSection";

const AboutMe = () => {
  return (
    <BorderedSection title="ABOUT devTeam">
      RCF devTeam is a technical dedicated team initialized in 2020 with a
      vision to gather RCF members who are passionate to learn web & app
      development. The devTeam provides them online & offline training in this
      regard. This is also a platform to share technical knowledge & to find
      solutions or help for any development issue which helps them to be better
      developers. Besides this team is also responsible to maintain the official
      website of RUET Career Forum.
    </BorderedSection>
  );
};

export default AboutMe;
