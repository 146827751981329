import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setToogleMood, setPrimaryColor } from "../../Actions/ColorActions";
import { ColorPicker } from "../../Components/ColorPicker";
import { Footer } from "../../Components/Footer";
import appImg from "../../Assets/NextMarvel/app.png";
import { Link } from "react-router-dom";

const NextMarvelPrivacyPage = ({
  primary,
  dark,
  light,
  setToogleMood,
  setPrimaryColor,
}) => {
  useEffect(() => {
    let flag = localStorage.getItem("light_mood");
    let colorFlag = localStorage.getItem("custom_color");
    if (flag === "true") {
      setToogleMood();
    }
    if (colorFlag) {
      setPrimaryColor({ hex: colorFlag });
    }
  }, [setToogleMood, setPrimaryColor]);
  return (
    <div
      style={{
        "--color_orange": primary,
        "--color_dark": dark,
        "--color_light": light,
        background: dark,
        width: "100%",
        height: "100%",
      }}
    >
      <ColorPicker />
      <div className="min_height pt-5 d-flex justify-content-center align-items-center flex-column">
        <img src={appImg} alt="Next Marvel Movie" style={{ maxWidth: 200 }} />
        <h1 className="text_primary pt-5">Next Marvel Movie</h1>
        <ul className="text_light">
          <li>
            This app uses local storage for cahcing the response from the API
          </li>
          <li>
            It uses <b>whenisthenextmcufilm</b> API for geting the data!
          </li>
          <li>It has Google AdMob ads</li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => ({
  primary: state.color.primary,
  dark: state.color.dark,
  light: state.color.light,
});

export default connect(mapStateToProps, { setToogleMood, setPrimaryColor })(
  NextMarvelPrivacyPage
);
