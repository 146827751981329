import React from "react";
import { BorderedSection } from "../Shared/BorderedSection";
import styles from "./AboutMe.module.css";

const AboutMe = () => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>About Me</span>
      <div
        className={`d-flex align-items-center justify-content-center ${styles.line_wrapper}`}
      >
        <div className={styles.line}></div>
        <div className={styles.circle}></div>
        <div className={styles.line}></div>
      </div>
      I am Noor Ajmir Tanvir Mahin, DevOps Engineer at{" "}
      <a href="https://raadar.com.bd/" target="_blank" className="link_text">
        Hyper System Ltd
      </a>
      . I am passionate about leveraging cutting-edge technologies to craft
      innovative solutions. I have strong proficiency in a wide array of
      technologies, including{" "}
      <b>
        AWS cloud infrastructure, Docker, Jenkins, Ubuntu, Prometheus, Grafana,
        Loki, and GitHub Actions.
      </b>{" "}
      Additionally, I have extensive full stack development experience using
      tools Like:{" "}
      <b>
        {" "}
        Next.js, React.js, Redux, Node.js, Express.js, TypeScript, Prisma,
        Sequalize, Mongoose, MySQL, MongoDB, etc.{" "}
      </b>{" "}
      and many more.
    </div>
  );
};

export default AboutMe;
