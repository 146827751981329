import { AiOutlineYoutube } from "react-icons/ai";

export const frontendDev = [
  {
    id: 1,
    title: "Basic HTML learning",
    content: (
      <div>
        <span className="d-block fs-4">Resources Youtube</span>
        <hr />

        <div className="">
          <a
            href="https://www.youtube.com/watch?v=UB1O30fR-EE"
            target={"_blank"}
            className="link_text "
          >
            <AiOutlineYoutube /> Traversy Media
          </a>
        </div>
        <div className="">
          <a
            href="https://www.youtube.com/watch?v=b4Rlq3KTSik"
            target={"_blank"}
            className="link_text "
          >
            <AiOutlineYoutube /> HTML Crash Course [Bangla]
          </a>
        </div>

        <span className="d-block fs-4 pt-4">Resources</span>
        <hr />

        <a
          href="https://www.w3schools.com/tags/default.asp"
          target={"_blank"}
          className="link_text "
        >
          <AiOutlineYoutube /> W3 Schools
        </a>
      </div>
    ),
    tags: [
      {
        id: 0,
        text: "HTML",
      },
      {
        id: 1,
        text: "HTML5",
      },
    ],
    description: "Learn the basics of HTML and HTML5",
  },
  {
    id: 2,
    title: "Basic CSS learning",
    content: (
      <div>
        <span className="d-block fs-4">Resources Youtube</span>
        <hr />

        <div className="">
          <a
            href="https://www.youtube.com/watch?v=yfoY53QXEnI"
            target={"_blank"}
            className="link_text "
          >
            <AiOutlineYoutube /> CSS Crash Course | Traversy Media
          </a>
        </div>
        <div className="">
          <a
            href="https://www.youtube.com/watch?v=bHHuozuq_Kk"
            target={"_blank"}
            className="link_text "
          >
            <AiOutlineYoutube /> CSS Crash Course [Bangla]
          </a>
        </div>

        <span className="d-block fs-4 pt-4">Resources</span>
        <hr />

        <a
          href="https://www.w3schools.com/cssref/default.asp"
          target={"_blank"}
          className="link_text "
        >
          <AiOutlineYoutube /> W3 Schools
        </a>
      </div>
    ),
    tags: [
      {
        id: 0,
        text: "Basic CSS",
      },
      {
        id: 1,
        text: "FLEX & GRID",
      },
      {
        id: 1,
        text: "Positioning",
      },
    ],
    description: "Learn the basics of CSS and CSS3",
  },
  {
    id: 3,
    title: "Task 1: HTML & CSS",
    content: (
      <div>
        <h2>Design simple hero section using only HTML & CSS</h2>
        <hr />
        <span className="text_primary">
          In this task you have to follow the Figma file and design the hero
          section.
        </span>
        <span className="d-block fs-4 pt-5">Reference</span>
        <hr />

        <div className="">
          <a
            href="https://www.figma.com/file/wq53fdjIEH57PkHqNrynZ2/Landing-page-(Community)?node-id=0%3A1&t=EwnxQ8jJzz7hl2Ay-1"
            target={"_blank"}
            className="link_text "
          >
            <AiOutlineYoutube /> Figma file
          </a>
        </div>
      </div>
    ),
    tags: [
      {
        id: 0,
        text: "Figma",
      },
      {
        id: 1,
        text: "CSS",
      },
      {
        id: 1,
        text: "HTML",
      },
    ],
    description: "Design your first task",
    link: "/devteam/task1",
  },
];
