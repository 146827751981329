import React from "react";
import styles from "./TaskComponent.module.css";

const TaskComponent = ({ children, title, description }) => {
  return (
    <div className="pt-5">
      <div className={styles.line}></div>
      <h2 className={`text-center ${styles.title}`}>{title}</h2>
      <span className={`${styles.desc} d-block pb-5`}>{description}</span>
      {children}
    </div>
  );
};

export default TaskComponent;
