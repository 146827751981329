import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setToogleMood, setPrimaryColor } from "../../Actions/ColorActions";
import { ColorPicker } from "../../Components/ColorPicker";
import { Footer } from "../../Components/Footer";
import appImg from "../../Assets/TuDo/TuDO_BANNER.png";
import googleImg from "../../Assets/NextMarvel/google.png";
import { Link } from "react-router-dom";

const TuDoAppPage = ({
  primary,
  dark,
  light,
  setToogleMood,
  setPrimaryColor,
}) => {
  useEffect(() => {
    let flag = localStorage.getItem("light_mood");
    let colorFlag = localStorage.getItem("custom_color");
    if (flag === "true") {
      setToogleMood();
    }
    if (colorFlag) {
      setPrimaryColor({ hex: colorFlag });
    }
  }, [setToogleMood, setPrimaryColor]);
  return (
    <div
      style={{
        "--color_orange": primary,
        "--color_dark": dark,
        "--color_light": light,
        background: dark,
        width: "100%",
        height: "100%",
      }}
    >
      <ColorPicker />
      <div className="min_height pt-5 d-flex justify-content-center align-items-center flex-column">
        <img src={appImg} alt="Next Marvel Movie" className="w-50 my-5" />

        <Link to="/tudo-vai/privacy" className="link_text">
          Privacy Policy
        </Link>
        <a href="">
          <img src={googleImg} alt="Google store" style={{ maxWidth: 200 }} />
        </a>
      </div>
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => ({
  primary: state.color.primary,
  dark: state.color.dark,
  light: state.color.light,
});

export default connect(mapStateToProps, { setToogleMood, setPrimaryColor })(
  TuDoAppPage
);
