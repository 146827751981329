import React from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { VscGithubAlt } from "react-icons/vsc";
import { SiNpm } from "react-icons/si";
import styles from "./ProjectItem.module.css";
import WinboxReact from "winbox-react";
import { Col, Container } from "react-bootstrap";
import { useState } from "react";

const ProjectItem = ({
  npm,
  github,
  title,
  description,
  tags,
  id,
  link,
  content,
}) => {
  const [modal, setModal] = useState(false);
  return (
    <Col md={12} sm={12} xs={12} className="p-2">
      {modal && (
        <WinboxReact
          title={title}
          background="--color_orange"
          onClose={() => setModal(false)}
          modern={true}
        >
          <Container>{content}</Container>
        </WinboxReact>
      )}
      <div className={styles.project__card}>
        <div className={styles.crd}>
          <span className={`d-block lead ${styles.title}`}>{title} </span>
          <div className={styles.tag__section}>
            {tags.map((tag) => (
              <div key={tag.id} className={styles.tag}>
                {tag.text}
              </div>
            ))}
          </div>
          <span className={`d-block ${styles.desc}`}>{description} </span>
          <div className="">
            {link ? (
              <a href={link} className={styles.icon}>
                <BsBoxArrowUpRight />
              </a>
            ) : (
              <button
                className={styles.btn__read}
                onClick={() => setModal(true)}
              >
                Details{" "}
              </button>
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ProjectItem;
