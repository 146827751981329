import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import styles from "./EnglishMoja.module.css";
import { FaGlobe, FaYoutube } from "react-icons/fa";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/effect-cube/effect-cube.min.css";

// import Swiper core and required modules
import SwiperCore, { EffectCube, Pagination, Autoplay } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import { BorderedSection } from "../Shared/BorderedSection";

// install Swiper modules
SwiperCore.use([EffectCube, Pagination, Autoplay]);

const EnglishMoja = () => {
  return (
    <BorderedSection title={"English Moja"}>
      <Row>
        <Col md={12} className="d-flex justify-content-around flex-column">
          <span className="d-block">
            English Moja is the most complex project I have ever worked on. I
            made the frontend part as well as some of the Flutter app part of
            the project. Next.js has been used for the frontend part. This site
            has a lot of traffic on the peak hours. This project was a great
            learning curve for me handeling clients.
          </span>
          <div className="pt-4">
            <a href="https://englishmojabd.com" className={styles.contact__btn}>
              <FaGlobe /> Visit Site
            </a>
          </div>
        </Col>
      </Row>
    </BorderedSection>
  );
};

export default EnglishMoja;
