import AmericanCournerTraning from "../Views/AmericanCournerTraning";
import { Task1 } from "../Views/Batch1";
import { DevTeamPage } from "../Views/DevTeamPage";
import LandingPage from "../Views/LandingPage/LandingPage";
import NextMarvelAppPage from "../Views/NextMarvelAppPage";
import NextMarvelPrivacyPage from "../Views/NextMarvelPrivacyPage";
import TuDoVaiPrivacy from "../Views/ToDuVaiPrivacy";
import TuDoAppPage from "../Views/TuDoAppPage";

const routes = [
  {
    path: "/",
    component: LandingPage,
    exact: true,
  },
  {
    path: "/tudo-vai/privacy",
    component: TuDoVaiPrivacy,
    exact: true,
  },
  {
    path: "/tudo-vai",
    component: TuDoAppPage,
    exact: true,
  },
  {
    path: "/devteam/task1",
    component: Task1,
    exact: true,
  },
  {
    path: "/next-marvel-movie",
    component: NextMarvelAppPage,
    exact: true,
  },
  {
    path: "/next-marvel-movie/privacy",
    component: NextMarvelPrivacyPage,
    exact: true,
  },
  {
    path: "/web",
    component: AmericanCournerTraning,
    exact: true,
  },
  {
    path: "/devteam",
    component: DevTeamPage,
    exact: true,
  },
  {
    path: "/test",
    component: LandingPage,
    routes: [
      {
        path: "/test/LandingPage",
        component: LandingPage,
      },
    ],
  },
  {
    path: "*",
    component: LandingPage,
  },
];

export default routes;
