import { GITHUB_AUTH_TOKEN } from "./secreat.s";
const data = {
  github_username: "TanvirMahin24",
  tkn: GITHUB_AUTH_TOKEN,
  base: "https://api.github.com/graphql",
  sec: "f9b4857b852df4723a7a44db63cef7a772600f64",
  id: "44f94358fcee34f3fe16",
};

export default data;
